import { Header } from "./Header";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Checkout = () => {
  const { plan } = useParams();
  const [phone, setPhone] = useState("");
  const navigate = useNavigate(); // Initialize navigate
  const [orderData, setOrderData] = useState({
    amount: null,
    currency: "INR",
    plan: plan,
    order_id: null,
    packageId: "",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    address2: "",
    country: "",
    state: null,
    city: "",
    pinCode: "",
    contactNumber: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
    contactNumber: "",
  });

  const countries = Country.getAllCountries();

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (countryIsoCode) =>
    State.getStatesOfCountry(countryIsoCode).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));

  const updatedCities = (countryIsoCode, stateIsoCode) =>
    City.getCitiesOfState(countryIsoCode, stateIsoCode).map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));

  const [error, setError] = useState(null);
  const [userId, setUserId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Fetch package_price from pricing-master endpoint
    axios
      .get(
        `https://render-chatbotsaasapp-backend.onrender.com/payment/pricing-master/${plan}`
      )
      .then((response) => {
        console.log("API Response:", response.data); // Log the response
        setOrderData((prevData) => ({
          ...prevData,
          amount: response.data.packagePrice * 100,
          packageId: response.data.packageId, // Ensure packagePrice is used correctly
        }));
      })
      .catch((error) => {
        console.error("Error fetching package price:", error);
        setError("Failed to fetch package price");
      });
  }, [plan]);

  useEffect(() => {
    const validateForm = () => {
      const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "address",
        "pinCode",
      ];
      const isValid = requiredFields.every(
        (field) => formData[field].trim() !== ""
      );
      setIsFormValid(isValid && phone.trim() !== "");
    };

    validateForm();
  }, [formData, phone]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error when user starts typing
    }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [actionMeta.name]: "", // Clear the error when user selects an option
    }));
    if (actionMeta.name === "country") {
      setFormData((prevData) => ({
        ...prevData,
        country: selectedOption,
        state: null,
        city: "",
      }));
    } else if (actionMeta.name === "state") {
      setFormData((prevData) => ({
        ...prevData,
        state: selectedOption,
        city: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [actionMeta.name]: selectedOption,
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `${name.replace(/^\w/, (c) => c.toUpperCase())} is required`,
      }));
    }
  };

  const handleSelectBlur = (selectedOption, actionMeta) => {
    if (!selectedOption) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [actionMeta.name]: `${actionMeta.name
          .replace(/^\w/, (c) => c.toUpperCase())
          .replace("contactNumber", "Contact number")} is required`,
      }));
    }
  };

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await axios.get(
          `https://render-chatbotsaasapp-backend.onrender.com/payment/get-id/${formData.email}`
        );
        setUserId(response.data.loginId);
        console.log(response.data.loginId);
      } catch (error) {
        console.error("Error fetching user ID:", error);
        setError("Failed to fetch user ID");
      }
    };

    if (formData.email.trim() !== "") {
      fetchUserId();
    }
  }, [formData.email]);

  const handleSubscription = async () => {
    setLoading(true); // Show loader

    const packageData = {
      packageId: orderData.packageId,
    };

    try {
      const response = await axios.post(
        "https://render-chatbotsaasapp-backend.onrender.com/payment/create-subscription",
        packageData
      );

      const subscriptionId = response.data.id;

      const options = {
        key: "rzp_test_LBjhBzKgcEj7hb",
        subscription_id: subscriptionId,
        name: "Acme Corp",
        description: "Subscription for starter package",
        handler: async function (response) {
          console.log(
            "Subscription successful with ID:",
            response.razorpay_subscription_id
          );
          console.log("Payment ID:", response.razorpay_payment_id);
          console.log("Signature:", response.razorpay_signature);

          const paymentData = {
            payment_id: response.razorpay_payment_id,
            subscriptionId: response.razorpay_subscription_id,
            package_id: plan,
            package_name: plan,
            payment_status: "success",
            payment_date: new Date().toISOString(),
            userId: userId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            address2: formData.address2,
            email: formData.email,
            country: formData.country.label,
            state: formData.state ? formData.state.label : "",
            city: formData.city ? formData.city.label : "",
            pinCode: formData.pinCode,
            contactNumber: phone,
            packagePrice: orderData.amount / 100,
            packageId: orderData.packageId,
          };

          try {
            await axios.post(
              "https://render-chatbotsaasapp-backend.onrender.com/payment/payments",
              paymentData
            );
            toast.success("Subscription and billing recorded successfully.", {
              autoClose: 2000,
              onClose: () =>
                navigate("/user-journey", { state: { userId: userId } }),
            });
            // Pass userId in state
          } catch (error) {
            toast.error("Failed to record subscription.");
            setError(
              error.response
                ? error.response.data.message
                : "Failed to record subscription."
            );
          }

          setLoading(false); // Hide loader
        },
        prefill: {
          name: formData.firstName + " " + formData.lastName,
          email: formData.email,
          contact: phone,
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("subscription.failed", function (response) {
        alert(`Subscription failed: ${response.error.description}`);
        setError(`Subscription failed: ${response.error.description}`);
        setLoading(false); // Hide loader on failure
      });

      rzp1.open();
    } catch (error) {
      toast.error("Failed to create subscription.");
      setError(
        error.response ? error.response.data.message : "Internal Server Error"
      );
      setLoading(false); // Hide loader on error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid) {
      try {
        handleSubscription();
      } catch (error) {
        toast.error("Payment failed. Please try again later."); // Show error toast
        console.error("Payment error:", error);
      }
    } else {
      toast.error("Please fill in all required fields."); // Show error toast
    }
  };

  return (
    <>
      <Header />
      <br />
      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-8">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                <h3 className="text-xl leading-normal font-semibold">
                  Billing address
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
                    <div className="lg:col-span-6">
                      <label className="form-label font-semibold">
                        First Name : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                        placeholder="First Name:"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          border: formErrors.firstName ? "1px solid red" : "",
                        }}
                        required
                      />
                      {formErrors.firstName && (
                        <span style={{ color: "red" }}>
                          {formErrors.firstName}
                        </span>
                      )}
                    </div>
                    <div className="lg:col-span-6">
                      <label className="form-label font-semibold">
                        Last Name : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                        placeholder="Last Name:"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        style={{
                          border: formErrors.lastName ? "1px solid red" : "",
                        }}
                      />
                      {formErrors.lastName && (
                        <span style={{ color: "red" }}>
                          {formErrors.lastName}
                        </span>
                      )}
                    </div>
                    <div className="lg:col-span-12">
                      <label className="form-label font-semibold">
                        Your Email : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        style={{
                          border: formErrors.email ? "1px solid red" : "",
                        }}
                      />
                      {formErrors.email && (
                        <span style={{ color: "red" }}>{formErrors.email}</span>
                      )}
                    </div>
                    <div className="lg:col-span-12">
                      <label className="form-label font-semibold">
                        Address : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                        placeholder="Address:"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        style={{
                          border: formErrors.address ? "1px solid red" : "",
                        }}
                      />
                      {formErrors.address && (
                        <span style={{ color: "red" }}>
                          {formErrors.address}
                        </span>
                      )}
                    </div>
                    <div className="lg:col-span-12">
                      <label className="form-label font-semibold">
                        Address 2 :
                      </label>
                      <input
                        type="text"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-2"
                        placeholder="Address:"
                        id="address2"
                        name="address2"
                        value={formData.address2}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="lg:col-span-4">
                      <label className="font-semibold">
                        Country: <span className="text-red-600">*</span>
                      </label>
                      <Select
                        name="country"
                        value={formData.country}
                        onChange={handleSelectChange}
                        options={updatedCountries}
                        onBlur={() =>
                          handleSelectBlur(formData.country, {
                            name: "country",
                          })
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="Country"
                      />
                      {formErrors.country && (
                        <span style={{ color: "red" }}>
                          {formErrors.country}
                        </span>
                      )}
                    </div>
                    <div className="lg:col-span-4">
                      <label className="font-semibold">
                        State: <span className="text-red-600">*</span>
                      </label>
                      <Select
                        name="state"
                        options={
                          formData.country
                            ? updatedStates(formData.country.value)
                            : []
                        }
                        value={formData.state}
                        onChange={handleSelectChange}
                        onBlur={() =>
                          handleSelectBlur(formData.state, { name: "state" })
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="State"
                      />
                      {formErrors.state && (
                        <span style={{ color: "red" }}>{formErrors.state}</span>
                      )}
                    </div>
                    <div className="lg:col-span-4">
                      <label className="font-semibold">
                        City: <span className="text-red-600">*</span>
                      </label>
                      <Select
                        name="city"
                        options={
                          formData.country && formData.state
                            ? updatedCities(
                                formData.country.value,
                                formData.state.value
                              )
                            : []
                        }
                        value={formData.city}
                        onChange={handleSelectChange}
                        onBlur={() =>
                          handleSelectBlur(formData.city, { name: "city" })
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder="City"
                        required
                      />
                      {formErrors.city && (
                        <span style={{ color: "red" }}>{formErrors.city}</span>
                      )}
                    </div>
                    <div className="lg:col-span-4">
                      <label className="form-label font-semibold">
                        Pin Code : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0 mt-0"
                        placeholder="Pin:"
                        id="pinCode"
                        name="pinCode"
                        value={formData.pinCode}
                        onChange={handleChange}
                        required
                        onBlur={handleBlur}
                        style={{
                          border: formErrors.pinCode ? "1px solid red" : "",
                          width: "100%",
                        }}
                      />
                      {formErrors.pinCode && (
                        <span style={{ color: "red" }}>
                          {formErrors.pinCode}
                        </span>
                      )}
                    </div>
                    <div className="lg:col-span-4">
                      <label className="form-label font-semibold">
                        Contact Number : <span className="text-red-600">*</span>
                      </label>
                      <PhoneInput
                        country={"in"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        containerStyle={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </form>
                <h3 className="text-xl leading-normal font-semibold mt-6">
                  Payment
                </h3>

                <div className="mb-4">
                  {loading ? (
                    <div className="flex justify-center">
                      <div className="circle-loader"></div>
                    </div>
                  ) : (
                    <input
                      type="button"
                      onClick={handleSubscription}
                      className={`py-2 px-5 inline-block tracking-wide border align-middle duration-500 text-base text-center rounded-md w-full ${
                        isFormValid
                          ? "bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white cursor-pointer"
                          : "bg-gray-400 border-gray-400 text-gray-200 cursor-not-allowed"
                      }`}
                      value="Pay now"
                      disabled={!isFormValid}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="lg:col-span-4">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                <div className="flex justify-between items-center">
                  <h5 className="text-lg font-semibold">Your Package</h5>
                  <a
                    href="javascript:void(0)"
                    className="bg-indigo-600 flex justify-center items-center text-white text-[10px] font-bold px-2.5 py-0.5 rounded-full h-5"
                  >
                    1
                  </a>
                </div>
                <div className="mt-4 rounded-md shadow dark:shadow-gray-800">
                  <div className="p-3 flex justify-between items-center">
                    <div>
                      <h5 className="font-semibold">
                        {capitalizeFirstLetter(plan)}
                      </h5>
                    </div>
                    <p className="text-slate-400 font-semibold">
                      $ {orderData.amount / 100}
                      {console.log(orderData.amount / 100)}
                    </p>
                  </div>
                  <div className="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
                    <div>
                      <h5 className="font-semibold">Total (USD)</h5>
                    </div>
                    <p className="font-semibold">$ {orderData.amount / 100}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      {/* Back to top */}
      <a
        href="#"
        id="back-to-top"
        className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 size-9 text-center bg-indigo-600 text-white leading-9"
      >
        <i className="uil uil-arrow-up" />
      </a>
      {/* Back to top */}
    </>
  );
};
