import { Footer } from "./Footer";
import { Header2 } from "./Header2";

export const TermsServices = () => (
  <>
    {/* Start Navbar */}
    <Header2 />
    <nav id="topnav" className="defaultscroll is-sticky">
      {/*end container*/}
    </nav>
    {/*end header*/}
    {/* End Navbar */}
    {/* Start Hero */}
    <section style={{backgroundColor:'#4F46E5'}} className="relative table w-full py-32 lg:py-40 bg-black-50 opacity-75 dark:bg-slate-800">
      <div className="container relative bg-black-50 opacity-75">
        <div className="grid grid-cols-1 text-center mt-10">
          <h3 className="text-3xl leading-normal font-semibold text-white">
            Terms of Services
          </h3>
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
      <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul className="tracking-[0.5px] mb-0 inline-block">
          <li className="text-white inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
            <a href="index.html">intelliXBot</a>
          </li>

          <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
            <i className="uil uil-angle-right-b" />
          </li>
          <li
            className="text-white inline-block uppercase text-[13px] font-bold text-indigo-600"
            aria-current="page"
          >
            Terms
          </li>
        </ul>
      </div>
    </section>
    {/*end section*/}
    <div className="relative">
      <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg
          className="w-full h-auto scale-[2.0] origin-top"
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    {/* End Hero */}
    {/* Start Terms & Conditions */}
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="md:flex justify-center">
          <div className="md:w-3/4">
            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <h5 className="text-xl font-semibold mb-4">Terms of Service for IntelliXbot</h5>
              <p className="text-slate-400 mt-4">
                Welcome to IntelliXbot, a service provided by Nexient AI. By accessing or using our services, you agree to comply with the following Terms of Service. These terms govern your access to and use of IntelliXbot, an AI chatbot development service. Please read them carefully.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">1. Introduction</h5>
              <p className="text-slate-400">
                IntelliXbot offers AI-powered chatbot development solutions designed to enhance customer engagement, automate tasks, and improve operational efficiency for businesses. These Terms of Service ("Terms") apply to all users of IntelliXbot's chatbot development services ("Services").
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">2. Scope of Services</h5>
              <p className="text-slate-400">
                IntelliXbot specializes in the development of customized AI chatbots to help businesses automate communication and streamline customer service. We offer development services that integrate with various communication channels. Our service does not include ongoing maintenance or AI model training post-deployment, unless specifically stated in your agreement.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">3. Pricing and Payment Terms</h5>
              <p className="text-slate-400">
                <b>Payment Models:</b> We offer both annual subscriptions and one-time payments for our chatbot development services.
              </p>
              <p className="text-slate-400">
                <b>Fees:</b> The pricing is set according to the specific package chosen at the time of engagement, as outlined in the service contract or agreement.
              </p>
              <p className="text-slate-400">
                <b>Payment Method:</b> Payments must be made via [insert payment methods].
              </p>
              <p className="text-slate-400">
                <b>No Refunds:</b> Payments are non-refundable, except in cases where we have not been able to provide the agreed-upon services.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">4. User Responsibility</h5>
              <p className="text-slate-400">
                By using our services, you agree to:
              </p>
              <ul className="list-none text-slate-400 mt-3">
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Provide accurate and complete information when using the chatbot and platform.
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Not use the service for illegal or unethical activities.
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Ensure that all content provided for the chatbot development is lawful and does not infringe upon the rights of third parties.
                </li>
              </ul>
              <h5 className="text-xl font-semibold mb-4 mt-8">5. Privacy and Data Security</h5>
              <p className="text-slate-400">
                By using our services, you acknowledge and accept the terms of our Privacy Policy, which governs how we collect, store, and use your data. We prioritize the security of your information and comply with relevant data protection laws. Your use of our services constitutes acceptance of both the IntelliXbot and Nexient AI privacy policies.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">6. Intellectual Property Rights</h5>
              <p className="text-slate-400">
                <b>Ownership:</b> Upon full payment for the service, you will own the developed chatbot and its associated source files.
              </p>
              <p className="text-slate-400">
                <b>License:</b> We retain the right to use any ideas or concepts developed during the engagement for future improvement of IntelliXbot, while respecting your proprietary rights.
              </p>
              <p className="text-slate-400">
                <b>Trademark Usage:</b> You may not use the IntelliXbot or Nexient AI trademarks, logos, or service names without prior written permission.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">7. Liability Limitations</h5>
              <p className="text-slate-400">
                <b>No Warranty:</b> We provide our services “as is” and make no warranties regarding their functionality, accuracy, or fitness for any particular purpose.
              </p>
              <p className="text-slate-400">
                <b>Limitation of Liability:</b> In no event shall IntelliXbot or Nexient AI be liable for any indirect, incidental, or consequential damages arising from your use of our services.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">8. Support and Maintenance</h5>
              <p className="text-slate-400">
                <b>Support:</b> We offer limited support for troubleshooting and basic inquiries related to your chatbot. Please contact us at info@nexientai.com for assistance.
              </p>
              <p className="text-slate-400">
                <b>Maintenance:</b> Ongoing maintenance, updates, or modifications to the chatbot are not included unless agreed upon in your service contract.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">9. Termination</h5>
              <p className="text-slate-400">
                <b>Termination by Us:</b> We reserve the right to terminate access to our services if you breach any terms or engage in illegal activities.
              </p>
              <p className="text-slate-400">
                <b>Termination by You:</b> You may terminate your subscription by contacting us at info@nexientai.com. However, no refunds will be provided for any unused portion of the subscription.
              </p>
              <p className="text-slate-400">
                <b>Effect of Termination:</b> Upon termination, all access to the services will be revoked, and you must cease using the chatbot developed by IntelliXbot.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">10. Modifications to Terms</h5>
              <p className="text-slate-400">
                We reserve the right to modify these Terms of Service at any time. Any changes will be posted on this page, with the updated date at the top. It is your responsibility to review these terms periodically.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">11. Governing Law</h5>
              <p className="text-slate-400">
                These Terms will be governed by and construed in accordance with the laws of India. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts located in [City, India].
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">12. Dispute Resolution</h5>
              <p className="text-slate-400">
                If you have any concerns or disputes regarding the services, please contact us at info@nexientai.com. We will attempt to resolve your issue in good faith. If a resolution cannot be reached, the dispute may be settled through mediation or binding arbitration.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">13. Contact Information</h5>
              <p className="text-slate-400">
                For any questions or concerns regarding these Terms of Service, please contact us at:
              </p>
              <p className="text-slate-400">
                Email: info@nexientai.com
              </p>
              <p className="text-slate-400">
                Website: www.nexientai.com
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">14. Acceptance of Terms</h5>
              <p className="text-slate-400">
                By visiting or using IntelliXbot’s services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service and the Privacy Policy. If you do not agree to these terms, please refrain from using our services.
              </p>
              <h5 className="text-xl font-semibold mb-4 mt-8">Disclaimer</h5>
              <p className="text-slate-400">
                This page is intended to provide a general overview of the Terms of Service for IntelliXbot. For more detailed terms, please refer to the full legal agreement presented to clients upon engaging with our services.
              </p>
            </div>
          </div>
          {/*end */}
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
    </section>
    {/*end section*/}
    {/* End Terms & Conditions */}
    <Footer />
    {/* Back to top */}
    {/* <a
      href="#"
      onclick="topFunction()"
      id="back-to-top"
      className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 size-9 text-center bg-indigo-600 text-white leading-9"
    >
      <i className="uil uil-arrow-up" />
    </a> */}
    {/* Back to top */}

    {/* LTR & RTL Mode Code */}
    {/* <div className="fixed top-[40%] -right-3 z-50">
      <a href="#" id="switchRtl">
        <span className="py-1 px-3 relative inline-block rounded-t-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-bold rtl:block ltr:hidden">
          LTR
        </span>
        <span className="py-1 px-3 relative inline-block rounded-t-md -rotate-90 bg-white dark:bg-slate-900 shadow-md dark:shadow dark:shadow-gray-800 font-bold ltr:block rtl:hidden">
          RTL
        </span>
      </a>
    </div> */}
  </>
);