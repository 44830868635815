// import { Footer } from "./Footer";
// import { Header2 } from "./Header2";

// export const Privacy = () => (
//   <>
//     <Header2 />
//     {/* Privacy Policy Page */}
//     <section style={{backgroundColor:'#4F46E5'}} className="relative table w-full py-32 bg-gray-50 dark:bg-slate-800">
//       <div className="container text-center">
//         <h1 className="text-4xl font-bold">Privacy Policy</h1>
//         {/* <p className="text-gray-600 dark:text-gray-300 mt-4">
//           Effective Date: [Insert Date]
//         </p> */}
//       </div>
//     </section>

//     {/* Privacy Policy Details */}
//     <section className="py-16">
//       <div className="container mx-auto px-4">
//         <div className="bg-white dark:bg-gray-900 p-8 shadow rounded">
//           <h2 className="text-2xl font-bold mb-4">Overview</h2>
//           <p className="text-gray-700 dark:text-gray-300">
//             IntelliXBot is committed to protecting the privacy of our users and
//             ensuring a secure experience. This Privacy Policy explains how we
//             collect, use, and safeguard your information when you interact with
//             our AI chatbot solutions and website.
//           </p>

//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             Information We Collect
//           </h3>
//           <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
//             <li>Personal Information: Name, email address, phone number.</li>
//             <li>
//               Usage Data: Pages visited, features used, and session duration.
//             </li>
//             <li>
//               Technical Data: IP address, browser type, and operating system.
//             </li>
//           </ul>

//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             How We Use Your Information
//           </h3>
//           <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
//             <li>To provide and improve our chatbot services.</li>
//             <li>To analyze and enhance user experience.</li>
//             <li>To communicate with you regarding support and updates.</li>
//             <li>To ensure platform security and reliability.</li>
//           </ul>

//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             Data Sharing and Security
//           </h3>
//           <p className="text-gray-700 dark:text-gray-300">
//             Your data is shared only with trusted partners and service providers
//             for operational purposes. We implement robust security measures,
//             including encryption and regular audits, to protect your
//             information.
//           </p>
//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             Acceptance of Policy
//           </h3>
//           <p className="text-gray-700 dark:text-gray-300">
//             By visiting and browsing the IntelliXbot website, you, as a user,
//             signify your acceptance of the terms and conditions set within this
//             policy. In the event of changes to the policy, your continued usage
//             will be construed as acceptance of those changes. For any questions
//             or concerns regarding this policy, feel free to contact us at
//             info@nexientai.com.
//           </p>

//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             Cookies and Tracking
//           </h3>
//           <p className="text-gray-700 dark:text-gray-300">
//             We use cookies to enhance your experience, analyze website traffic,
//             and improve services. You can manage your cookie preferences in your
//             browser settings.
//           </p>

//           <h3 className="text-xl font-semibold mt-8 mb-4">Your Rights</h3>
//           <p className="text-gray-700 dark:text-gray-300">
//             You have the right to access, modify, or delete your data. To
//             exercise these rights, contact us at{" "}
//             <a
//               href="mailto:info@nexientai.com"
//               className="text-indigo-600 hover:underline"
//             >
//               info@nexientai.com
//             </a>
//             .
//           </p>

//           <h3 className="text-xl font-semibold mt-8 mb-4">
//             Updates to This Policy
//           </h3>
//           <p className="text-gray-700 dark:text-gray-300">
//             This Privacy Policy may be updated periodically. Please check this
//             page for any changes.
//           </p>

//           <p className="text-gray-700 dark:text-gray-300 mt-8">
//             For questions or concerns, contact us at{" "}
//             <a
//               href="mailto:support@intellixbot.com"
//               className="text-indigo-600 hover:underline"
//             >
//               info@nexientai.com
//             </a>
//             .
//           </p>
//         </div>
//       </div>
//     </section>
//     <Footer />
//   </>
// );

import { Footer } from "./Footer";
import { Header2 } from "./Header2";

export const Privacy = () => (
  <>
    <Header2 />
    {/* Privacy Policy Page */}
    <section style={{ backgroundColor: '#4F46E5' }} className="relative table w-full py-32">
      <div className="container text-center">
        <h1 className="text-4xl font-bold text-white">Privacy Policy</h1>
        {/* <p className="text-gray-600 dark:text-gray-300 mt-4">
          Effective Date: [Insert Date]
        </p> */}
      </div>
    </section>

    {/* Privacy Policy Details */}
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="bg-white dark:bg-gray-900 p-8 shadow rounded">
          <h2 className="text-2xl font-bold mb-4">Overview</h2>
          <p className="text-gray-700 dark:text-gray-300">
            IntelliXBot is committed to protecting the privacy of our users and
            ensuring a secure experience. This Privacy Policy explains how we
            collect, use, and safeguard your information when you interact with
            our AI chatbot solutions and website.
          </p>

          <h3 className="text-xl font-semibold mt-8 mb-4">
            Information We Collect
          </h3>
          <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
            <li>Personal Information: Name, email address, phone number.</li>
            <li>
              Usage Data: Pages visited, features used, and session duration.
            </li>
            <li>
              Technical Data: IP address, browser type, and operating system.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8 mb-4">
            How We Use Your Information
          </h3>
          <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
            <li>To provide and improve our chatbot services.</li>
            <li>To analyze and enhance user experience.</li>
            <li>To communicate with you regarding support and updates.</li>
            <li>To ensure platform security and reliability.</li>
          </ul>

          <h3 className="text-xl font-semibold mt-8 mb-4">
            Data Sharing and Security
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            Your data is shared only with trusted partners and service providers
            for operational purposes. We implement robust security measures,
            including encryption and regular audits, to protect your
            information.
          </p>
          <h3 className="text-xl font-semibold mt-8 mb-4">
            Acceptance of Policy
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            By visiting and browsing the IntelliXbot website, you, as a user,
            signify your acceptance of the terms and conditions set within this
            policy. In the event of changes to the policy, your continued usage
            will be construed as acceptance of those changes. For any questions
            or concerns regarding this policy, feel free to contact us at
            info@nexientai.com.
          </p>

          <h3 className="text-xl font-semibold mt-8 mb-4">
            Cookies and Tracking
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            We use cookies to enhance your experience, analyze website traffic,
            and improve services. You can manage your cookie preferences in your
            browser settings.
          </p>

          <h3 className="text-xl font-semibold mt-8 mb-4">Your Rights</h3>
          <p className="text-gray-700 dark:text-gray-300">
            You have the right to access, modify, or delete your data. To
            exercise these rights, contact us at{" "}
            <a
              href="mailto:info@nexientai.com"
              className="text-indigo-600 hover:underline"
            >
              info@nexientai.com
            </a>
            .
          </p>

          <h3 className="text-xl font-semibold mt-8 mb-4">
            Updates to This Policy
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            This Privacy Policy may be updated periodically. Please check this
            page for any changes.
          </p>

          <p className="text-gray-700 dark:text-gray-300 mt-8">
            For questions or concerns, contact us at{" "}
            <a
              href="mailto:support@intellixbot.com"
              className="text-indigo-600 hover:underline"
            >
              info@nexientai.com
            </a>
            .
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </>
);