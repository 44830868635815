import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useNavigate } from "react-router-dom";

const CalendlyComponent = () => {
  const navigate = useNavigate();

  useCalendlyEventListener({
    onEventScheduled: () => {
      // Add a 4-second timeout before navigating to the login page
      setTimeout(() => {
        navigate("/login");
      }, 4000);
    },
  });

  return (
    <>
      <div style={{ height: "600px" }}>
        <InlineWidget url="https://calendly.com/tushar-harinkhede-nexientai" />
      </div>
    </>
  );
};

export default CalendlyComponent;
