export const ChatbotFeatures = () => (
  <>
    <section className="relative md:py-24 py-16" id="features">
      <div className="container relative md:mt-1 mt-1">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">
            Chatbot Features
          </h6>
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Seamless Integration Across Multiple Channels
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Our AI chatbot supports seamless integration across various
            communication channels, ensuring your business is always connected
            with your customers.
          </p>
        </div>

        {/* Chatbot Features start */}
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-comment-alt-message"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Web Chat
              </a>
              <p className="text-slate-400 mt-3">
                Engage with visitors directly on your website through our
                intuitive web chat interface.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-skype"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Skype
              </a>
              <p className="text-slate-400 mt-3">
                Connect with customers on Skype for real-time, efficient
                communication.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-slack"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Slack
              </a>
              <p className="text-slate-400 mt-3">
                Stay in touch with your team and customers using our Slack
                integration.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-google"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Google Chat
              </a>
              <p className="text-slate-400 mt-3">
                Utilize Google Chat to maintain seamless communication within
                your organization.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-comment-lines"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                LINE
              </a>
              <p className="text-slate-400 mt-3">
                Engage customers in Asia with our LINE integration for targeted
                communication.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-telegram-alt"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Telegram
              </a>
              <p className="text-slate-400 mt-3">
                Communicate with your audience on Telegram for a fast, reliable
                connection.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-whatsapp"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                WhatsApp
              </a>
              <p className="text-slate-400 mt-3">
                Reach customers directly through the world's most popular
                messaging app, WhatsApp.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-microsoft"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Microsoft Teams
              </a>
              <p className="text-slate-400 mt-3">
                Integrate with Microsoft Teams for effective communication and
                collaboration.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>

          <div className="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
            <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
              <i className="uil uil-twitter"></i>
            </div>
            <div className="content mt-7">
              <a
                href="/"
                className="title h5 text-lg font-medium hover:text-indigo-600"
              >
                Twitter
              </a>
              <p className="text-slate-400 mt-3">
                Keep the conversation going with your followers on Twitter
                through automated responses.
              </p>
              <div className="mt-5"></div>
            </div>
          </div>
        </div>
        {/* Chatbot Features end */}
      </div>
    </section>
    {/* -------------------------------------------------------------------------------------- */}

    <section className="relative md:py-24 py-16" id="benefits">
      <div className="container relative md:mt-24 mt-16">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
            Discover the Advantages
          </h3>
          <p className="text-slate-400 max-w-xl mx-auto">
            Explore how our platform can enhance your business operations with
            minimal effort and maximum results.
          </p>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">
                Effortless Efficiency
              </h5>
              <p className="text-slate-400">
                Cut down setup time and launch quickly with our easy-to-use
                visual builder, designed for users of all technical levels.
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">
                Revenue Enhancement
              </h5>
              <p className="text-slate-400">
                Increase conversions and retain customers by offering
                personalized interactions throughout their journey.
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">
                Enhanced Interaction
              </h5>
              <p className="text-slate-400">
                Replace lengthy forms with engaging, conversational bots to see
                a significant increase in response rates.
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">
                Boost Customer Satisfaction
              </h5>
              <p className="text-slate-400">
                Prevent customer drop-off due to delays by offering quick
                self-service options and responsive support.
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">
                Data-Driven Success
              </h5>
              <p className="text-slate-400">
                Leverage detailed analytics to refine your strategies with
                exportable metrics, reports, and performance insights.
              </p>
            </div>
          </div>

          <div className="flex">
            <div className="flex-1">
              <h5 className="mb-2 text-xl font-semibold">Team Collaboration</h5>
              <p className="text-slate-400">
                Boost team productivity with a chat manager featuring routing
                rules, tools, and quick-access functionalities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
